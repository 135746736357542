const Database = [
    {
      id: '12345678-abcd-1234-abcd-1234567890ab',
      senderName: 'John Smith',
      senderEmail: 'john@example.com',
      regionLanguage: 'EU/ASIA',
      type: 'Two Way Agreement',
      projectName: 'Project X',
      projectDescription: 'A new project for improving efficiency',
      internalCompanyName: 'Acme Inc.',
      internalCompanyShortName: 'Acme',
      internalRecipientName: 'Jane Doe',
      internalRecipientEmail: 'jane@acme.com',
      externalCompanyName: 'Contoso Corp.',
      externalCompanyShortName: 'Contoso',
      externalRecipientName: 'Bob Smith',
      externalRecipientEmail: 'bob@contoso.com',
      currentDate: '2022-01-01',
      internalSignature: false,
      internalJobTitle: 'CEO',
      externalSignature: false,
      externalJobTitle: 'CTO',
    },
    {
      id: 'abcdefgh-abcd-1234-abcd-1234567890ab',
      senderName: 'Jane Doe',
      senderEmail: 'jane@example.com',
      regionLanguage: 'EU/ASIA',
      type: 'One Way Agreement',
      projectName: 'Project Y',
      projectDescription: 'A new project for streamlining processes',
      internalCompanyName: 'Acme Inc.',
      internalCompanyShortName: 'Acme',
      internalRecipientName: 'John Smith',
      internalRecipientEmail: 'john@acme.com',
      externalCompanyName: 'Contoso Corp.',
      externalCompanyShortName: 'Contoso',
      externalRecipientName: 'Bob Smith',
      externalRecipientEmail: 'bob@cont',
      currentDate: '2022-01-01',
      internalSignature: false,
      internalJobTitle: 'CEO',
      externalSignature: false,
      externalJobTitle: 'CTO',
      },
    ]

export default Database;