export default function NavigationHomeButton({location, name, icon}) {

    function NavigatePage() {
        if (location) {
            window.location.href = location
        }
    }

    return (
        <div className='nav_home_button' onClick={NavigatePage} >
            <div>{icon}</div>
            <p className='button_text' style={{marginRight:'auto', marginLeft: '0.4rem'}}>{name}</p>
        </div>
    )
}