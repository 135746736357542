import React, { useState } from 'react';
import Header from '../UniversalComponents/Header';
import axios from 'axios';

export default function EntryForm() {
    const [entry, setEntry] = useState({
        senderName: '',
        senderEmail: '',
        regionLanguage: 'China',
        type: 'One Way Agreement',
        projectName: '',
        projectDescription: '',
        internalCompanyName: '',
        internalCompanyShortName: '',
        internalRecipientName: '',
        internalRecipientEmail: '',
        externalCompanyName: '',
        externalCompanyShortName: '',
        externalRecipientName: '',
        externalRecipientEmail: '',
        currentDate: '',
        internalJobTitle: '',
        externalJobTitle: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEntry((prevEntry) => ({ ...prevEntry, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(entry)
        const data = {
            entry,
        };
        entry.currentDate = 'now'
        if (validateEntry()) {
            alert('The Document has been created!\nCheck the view agreements section to view the documents.\nYou will be notified by email when the document has been signed by both parties');
            axios({
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
                method: 'post',
                url: 'http://localhost:8080/data', 
                data: data
            })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            alert('Unable to create Document\nMake sure everything is filled in correctly');
        }
    };

    const validateEntry = () => {
        if (Object.values(entry).some(value => value.trim() === '')) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <div>
            <Header />
            <form style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                backgroundColor: '#f1f1f1',
                border: '2px solid #ccc',
                borderRadius: '5px',
                padding: '20px',
                maxWidth: '800px',
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'space-between'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ width: '50%', textAlign: 'center', marginRight: 50 }}>
                        {/* {Column 1} */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="senderName" style={{ fontWeight: 'bold' }}>Sender Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="senderName"
                                value={entry.senderName}
                                onChange={handleChange}
                            />
                        </div>

                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="regionLanguage" style={{ fontWeight: 'bold' }}>Region Language:</label>
                            <select style={{ borderRadius: '5px', padding: '5px', width: 182}}
                                type="text"
                                name="type"
                                value={entry.regionLanguage}
                                onChange={handleChange}>
                                <option value="CHINA">China</option>
                                <option value="EU/ASIA">EU/ASIA</option>
                                <option value="LATAM_Portuguese">Latin America - Portuguese</option>
                                <option value="LATAM_Spanish">Latin America - Spanish</option>
                                <option value="USA">USA</option>
                            </select>
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="projectName" style={{ fontWeight: 'bold' }}>Project Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="projectName"
                                value={entry.projectName}
                                onChange={handleChange}
                            />
                        </div>
                        <br />
                        <hr style={{ width: '120%', border: '1px solid #ccc' }} />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="internalCompanyName" style={{ fontWeight: 'bold' }}>Internal Company Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="internalCompanyName"
                                value={entry.internalCompanyName}
                                onChange={handleChange}
                            />
                        </div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="internalRecipientName" style={{ fontWeight: 'bold' }}>Internal Recipient Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="internalRecipientName"
                                value={entry.internalRecipientName}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="internalJobTitle" style={{ fontWeight: 'bold' }}>Internal Job Title:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="internalJobTitle"
                                value={entry.internalJobTitle}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <br />
                        <hr style={{ width: '120%', border: '1px solid #ccc' }} />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="externalCompanyName" style={{ fontWeight: 'bold' }}>External Company Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="externalCompanyName"
                                value={entry.externalCompanyName}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="externalRecipientName" style={{ fontWeight: 'bold' }}>External Recipient Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="externalRecipientName"
                                value={entry.externalRecipientName}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="externalJobTitle" style={{ fontWeight: 'bold' }}>External Job Title:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="externalJobTitle"
                                value={entry.externalJobTitle}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div style={{ width: '50%', textAlign: 'center' }}>
                        {/* {Column 2} */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="senderEmail" style={{ fontWeight: 'bold' }}>Sender Email:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="email"
                                name="senderEmail"
                                value={entry.senderEmail}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="type" style={{ fontWeight: 'bold' }}>Type:</label>
                            <select style={{ borderRadius: '5px', padding: '5px',  width: 182 }}
                                type="text"
                                name="type"
                                value={entry.type}
                                onChange={handleChange}>
                                <option value="One Way Agreement">One way agreement</option>
                                <option value="Two Way Agreement">Two way agreement</option>
                            </select>
                        </div>
                        <div style={{ height: 71 }} />
                        <hr style={{ width: '100%', border: '1px solid #ccc' }} />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="internalCompanyShortName" style={{ fontWeight: 'bold' }}>Internal Short Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="internalCompanyShortName"
                                value={entry.internalCompanyShortName}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="internalRecipientEmail" style={{ fontWeight: 'bold' }}>Internal Recipient Email:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="email"
                                name="internalRecipientEmail"
                                value={entry.internalRecipientEmail}
                                onChange={handleChange}
                            /></div>
                        <div style={{ height: 71 }} />
                        <br />
                        <hr style={{ width: '100%', border: '1px solid #ccc' }} />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="externalCompanyShortName" style={{ fontWeight: 'bold' }}>External Short Name:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="text"
                                name="externalCompanyShortName"
                                value={entry.externalCompanyShortName}
                                onChange={handleChange}
                            /></div>
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <label htmlFor="externalRecipientEmail" style={{ fontWeight: 'bold' }}>External Recipient Email:</label>
                            <input
                                style={{ borderRadius: '5px', padding: '5px' }}
                                type="email"
                                name="externalRecipientEmail"
                                value={entry.externalRecipientEmail}
                                onChange={handleChange}
                            /></div>
                        <br />
                    </div>
                </div>
                <br />
                <div style={{ margin: 20 }}>
                    <label htmlFor="projectDescription" style={{ fontWeight: 'bold' }}>Project Description:</label>
                    <textarea
                        style={{ borderRadius: '5px', padding: '5px', fontSize: '14px' }}
                        type="text"
                        name="projectDescription"
                        value={entry.projectDescription}
                        onChange={handleChange}
                        rows="10"
                        cols="50"
                    />
                </div>
                <br />
                <button
                    type="submit"
                    style={{
                        backgroundColor: '#114438',
                        border: 'none',
                        color: 'white',
                        padding: '15px 32px',
                        textAlign: 'center',
                        textDecoration: 'none',
                        display: 'inline-block',
                        fontSize: '16px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}

