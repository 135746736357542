const AgreementText = [
    {
        "FileType": "One Way Agreement",
        "FileRegion": "CHINA",
        "Data": "This is the chinese one way agreement"
    },
    {
        "FileType": "Two Way Agreement",
        "FileRegion": "CHINA",
        "Data": "This is the chinese two way agreement"
    },
    {
        "FileType": "One Way Agreement",
        "FileRegion": "EU/ASIA",
        "Data":
            "***ThatCompany***\n" +
            "***ThatAddress***\n" +
            "***Today'sDate***\n" +
            "FAO: ***FAO***\n" +
            "Dear Sirs\n" +
            "Confidentiality Agreement\n" +
            "***ThisCompany*** (“***ThisCompanyShortName***”) wishes to disclose certain confidential and proprietary information to ***ThatCompany*** (“***ThatCompanyKnownAs***”) for the purposes of ***TheProject*** (“the Purpose”).\n" +
            "<ol><li> In this letter:\n" +
            "<ol type='a'><li>\"Confidential Information\" means all samples, documents, papers, drawings, diagrams, discs, tapes and other information(whether in physical form or otherwise) disclosed in any manner(including disclosed orally or visually) by ***ThisCompanyShortName*** to you hereunder including(without limitation) scientific information and technical know - how relating to any materials, products or processes, including inventions(whether patentable or not) and whether or not reduced to writing or recorded in any other tangible form, information relating to ***ThisCompanyShortName***'s respective businesses and product lines, its plans for its businesses, discussions on future products, the existence of the current discussions between the parties, this letter and the provisions hereof, but excluding information which is: -\n" +
            "<ol type='i'><li>at the time of disclosure to you, publicly available or subsequently becomes so, otherwise than as a result of any breach of this letter by you or your Related Persons or any other obligation of confidentiality owed by you or your Related Persons to ***ThisCompanyShortName***;\n" +
            "<li>known to you before the date it is disclosed by ***ThisCompanyShortName*** or its Related Persons or is lawfully obtained by you after that date, other than from ***ThisCompanyShortName*** or any of its Related Persons and which, in either case, has not been obtained in violation of any obligation of confidentiality to ***ThisCompanyShortName***; \n" +
            "<li>independently discovered after the date of this letter by you or your Related Persons without the aid, application or use of any Confidential Information; or\n" +
            "<li>approved for release with the prior written permission of ***ThisCompanyShortName***;Confidential Information shall not be deemed to be within the exceptions specified above(\"Exceptions\") merely because it is embraced by more general information contained within the Exceptions.\n" +
            "</ol><li> \"Related Persons\" means your advisers, agents, consultants, customers and clients, actual and prospective and, where you are a company, your associated companies, subsidiary undertakings, parent undertakings, directors and employees at any time during the period in which the provisions of this letter apply.For the avoidance of doubt, you shall remain responsible to ***ThisCompanyShortName*** for any failure of any of your Related Persons to observe the terms and conditions of this letter regarding the use and confidentiality of the Confidential Information.\n</ol>" +
            "<li>In consideration of ***ThisCompanyShortName*** agreeing to disclose Confidential Information to you, you undertake to ***ThisCompanyShortName*** that you shall:\n" +
            "<ol type='a'><li>keep all the Confidential Information secret;\n" +
            "<li>use the Confidential Information only for the Purpose;\n" +
            "<li>only disclose the Confidential Information to such of your Related Persons as are strictly necessary for the Purpose and other persons whom ***ThisCompanyShortName*** has agreed in writing may receive it;\n" +
            "<li>only make copies of the Confidential Information as may be necessary to achieve the Purpose; \n" +
            "<li>ensure that each person to whom Confidential Information is disclosed is fully aware of your obligations under this letter and require that each such person complies with the obligations as though they were a party to this letter; and\n" +
            "<li>give all assistance reasonably required by ***ThisCompanyShortName*** to enable ***ThisCompanyShortName*** to prevent any improper use of the Confidential Information by any of your directors, employees, advisers, agents, sub - contractors and consultants.\n</ol>" +
            "<li>You may disclose Confidential Information to the extent required by an order of any court of competent jurisdiction or any competent judicial, governmental, regulatory or supervisory body or by the rules of any listing authority or stock exchange or by the laws or regulations of any country with jurisdiction over the affairs of you or ***ThisCompanyShortName***.In such cases, you shall (to the extent permitted by law, regulation, order or rule and reasonably practicable) inform ***ThisCompanyShortName*** of the full circumstances and the information required to be disclosed and consult with ***ThisCompanyShortName*** as to possible steps to avoid or limit disclosure.\n" +
            "<li>If ***ThisCompanyShortName*** so requests in writing, you shall return to ***ThisCompanyShortName*** or destroy all original and copy documents containing Confidential Information and analyses, studies, compilations and other materials derived from the Confidential Information.\n" +
            "<li>No representation or warranty is made or given as to the accuracy or the completeness of the Confidential Information or as to the reasonableness of any assumptions on which any of the same is based, and you agree, for yourself and on behalf of your Related Persons, with ***ThisCompanyShortName***, for itself and for each of its Related Persons, that(without prejudice to any liability for fraud) neither ***ThisCompanyShortName*** nor any of its Related Persons shall have any liability to you or to any of your Related Persons resulting from the use of Confidential Information.\n" +
            "<li>This letter shall not be construed as evidencing any intent to contract and either party may choose not to enter into a business or contractual relationship with the other or may enter into similar discussions or contractual relationships with third parties regarding Confidential Information which it has disclosed under this letter.\n" +
            "<li>Disclosure by ***ThisCompanyShortName*** hereunder to you of Confidential Information confers no proprietary rights on you and is only for the purposes of this letter.In particular (without limitation) it is agreed that this letter does not require either party to develop, announce or deliver any product and no licence is hereby granted directly or indirectly by ***ThisCompanyShortName*** to you under any patent, trade mark, copyright or other intellectual property right now held by, or which may be obtained by, or which is or may be licensable by ***ThisCompanyShortName***.\n" +
            "<li>You shall not use the Confidential Information or the results of any test, analysis, investigation or development based upon any Confidential Information(whether or not the obtaining of such was within the Purpose) for applying for or gaining any registered intellectual property right in any country.To the extent that you are in breach of your obligation in this paragraph 8, you shall hold any such right or application therefore on trust for ***ThisCompanyShortName***.\n" +
            "<li>Without prejudice to the generality of this letter, you shall not analyse the chemical or physical composition or structure of any sample that is disclosed to you by ***ThisCompanyShortName***, nor reverse engineer or perform any test other than such tests as may be necessary for the Purpose, or as may otherwise be expressly agreed in writing by ***ThisCompanyShortName***.\n" +
            "<li>Without prejudice to any other rights or remedies that any party may have, you(for yourself and on behalf of your Related Persons) acknowledge and agree that damages alone would not be an adequate remedy for any breach by you or any of your Related Persons of the provisions of this letter, and that the remedies of injunction and specific performance as well as any other equitable relief for any threatened or actual breach of the provisions of this letter by you and / or any of your Related Persons would be more appropriate remedies and that no proof of special damages shall be necessary for the enforcement of this letter.\n" +
            "<li>No failure by ***ThisCompanyShortName*** in exercising any right, power or privilege hereunder shall constitute a waiver of any such right, power or privilege, nor shall any single or partial exercise thereof preclude any further exercise of any such right, power or privilege.\n" +
            "<li>This letter and all obligations and rights under it shall terminate on the tenth anniversary of its execution.\n" +
            "<li>The Parties do not intend that any of its terms will be enforceable by virtue of the Contracts (Rights of Third Parties) Act 1999 by any person not a party to it.\n" +
            "<li>This letter shall be governed by and interpreted in accordance with English law and the parties agree to submit to the exclusive jurisdiction of the English courts. If you are in agreement with the terms of this letter, please sign and return one copy.\n</ol>" +
            "Yours faithfully *** InitialsAndSurname ***\n" +
            "*** JobTitle ***\n" +
            "for and on behalf of ***ThisCompany***\n" +
            "We agree to the terms of this letter\n" +
            "for and on behalf of ***ThatCompany***\n" +
            "Dated: ***Today'sDate***"
    },
    {
        "FileType": "Two Way Agreement",
        "FileRegion": "EU/ASIA",
        "Data": "This is the EU/ASIA two way agreement"
    },
    {
        "FileType": "One Way Agreement",
        "FileRegion": "LATAM_Portuguese",
        "Data": "This is the Latin American Portuguese one way agreement"
    },
    {
        "FileType": "Two Way Agreement",
        "FileRegion": "LATAM_Portuguese",
        "Data": "This is the Latin American Portuguese two way agreement"
    },
    {
        "FileType": "One Way Agreement",
        "FileRegion": "LATAM_Spanish",
        "Data": "This is the Latin American Spanish one way agreement"
    },
    {
        "FileType": "Two Way Agreement",
        "FileRegion": "LATAM_Spanish",
        "Data": "This is the Latin American Spanish two way agreement"
    },
    {
        "FileType": "One Way Agreement",
        "FileRegion": "USA",
        "Data": "This is the USA one way agreement"
    },
    {
        "FileType": "Two Way Agreement",
        "FileRegion": "USA",
        "Data": "This is the USA two way agreement"
    },
];

export default AgreementText;