import React, { useState } from 'react';
import axios from 'axios';
import Header from '../UniversalComponents/Header';

export default function Temp() {
  const [name, setName] = useState('');
  const [region, setRegion] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    //const item = { name, region };
    //axios.post('http://localhost:8080/data', item)
    axios({
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      method: 'post',
      url: 'http://localhost:8080/data',
      data: {
        name: name,
        region: region
      },
    })
      .then((res) => {
        console.log(res.data);
        setName('');
        setRegion('');
      });
  };

  return (
    <div>
      <Header/>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <br />
        <label>
          Region:
          <input type="text" value={region} onChange={(e) => setRegion(e.target.value)} />
        </label>
        <br />
        <button type="submit">Add Item</button>
      </form>
    </div>
  );
}