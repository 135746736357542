import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { AiOutlineDownload } from 'react-icons/ai';
import Header from "../UniversalComponents/Header";
import {Row} from 'react-bootstrap'

export default function DownloadDocuments(){

    const pdfFiles = [
        {
            id: 1,
            name: 'China One Way Agreement',
            path: require('../TextFiles/CHINA_OneWayAgreement_Template.pdf')
        },
        {
            id: 2,
            name: 'China Two Way Agreement',
            path: require('../TextFiles/CHINA_TwoWayAgreement_Template.pdf')
        },
        {
            id: 3,
            name: 'EU and Asia One Way Agreement',
            path: require('../TextFiles/EU_ASIA_OneWayAgreement_Template.pdf')
        },
        {
            id: 4,
            name: 'EU and Asia Two Way Agreement',
            path: require('../TextFiles/EU_ASIA_TwoWayAgreement_Template.pdf')
        },
        {
            id: 5,
            name: 'Latin American One Way Agreement (Portuguese)',
            path: require('../TextFiles/LATAM_PortugueseOneWayAgreement_Template.pdf')
        },
        {
            id: 6,
            name: 'Latin American Two Way Agreement (Portuguese)',
            path: require('../TextFiles/LATAM_PortugueseTwoWayAgreement_Template.pdf')
        },
        {
            id: 7,
            name: 'Latin American One Way Agreement (Spanish)',
            path: require('../TextFiles/LATAM_SpanishOneWayAgreement_Template.pdf')
        },
        {
            id: 8,
            name: 'Latin American Two Way Agreement (Spanish)',
            path: require('../TextFiles/LATAM_SpanishTwoWayAgreement_Template.pdf')
        },
        {
            id: 9,
            name: 'USA One Way Agreement',
            path: require('../TextFiles/USA_OneWayAgreement_Template.pdf')
        },
        {
            id: 10,
            name: 'USA Two Way Agreement',
            path: require('../TextFiles/USA_TwoWayAgreement_Template.pdf')
        },
        // Add more PDF files here...
      ];

    return(
        <div>
            <Header />
            <Row style={{marginLeft:20, marginRight:20, marginTop: 50}}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow style={{backgroundColor: 'lightGray', justifyContent: 'space-between'}}>
                        <TableCell><p className='table_heading_text'>ID</p></TableCell>
                        <TableCell><p className='table_heading_text'>Name</p></TableCell>
                        <TableCell align="right"><p className='table_heading_text'>Download</p></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {pdfFiles.map((pdf) =>(
                        <TableRow key={pdf.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>  
                            <TableCell component="th" scope="row">{pdf.id}</TableCell>
                            <TableCell component="th" scope="row">{pdf.name}</TableCell>
                            <TableCell align='right'  style={{paddingRight: '2.5rem'}}><a href={pdf.path}><AiOutlineDownload style={{height: 50, fontSize: '2.5rem', cursor: 'pointer'}}/></a></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Row>
        </div>
    );
}