import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CreateAgreementScreen from './CreateAgreementScreen/CreateAgreementScreen'
import Table from './AgreementListScreen/Table'
import DisplayDocument from './DisplayDocumentScreen/DisplayDocument';
import DownloadDocuments from './DownloadDocumentsScreen/DownloadDocuments';
import Temp from './Temp/Temp'
import LargeHomeScreen from './HomeScreen/HomeScreen';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path='/' element={<LargeHomeScreen />}></Route>
        <Route exact path='/download_documents' element={<DownloadDocuments/>}></Route>
        <Route exact path='/create_agreement' element={<CreateAgreementScreen />}></Route>
        <Route exact path='/agreement_list' element={<Table />}></Route>
        <Route path='/view/:GUID' element={<DisplayDocument/>}></Route>
        <Route exact path='temp' element={<Temp/>}></Route>
      </Routes>
    </Router>
  </React.StrictMode>
);
