import Header from "../UniversalComponents/Header"
import React from 'react'
import NavigationHomeButton from "./Components/NavigationHomeButtons"
import { HiDocumentText } from "react-icons/hi";
import { AiFillFolderOpen } from "react-icons/ai";
import guidancePDF from '../TextFiles/Guidance_Notes_-_Confidentiality_Agreements.pdf'
import PlaybookPDF from '../TextFiles/Confidentiality Agreement Croda Playbook.pdf'
import confidentialityAgreement from '../Images/confidentiality_agreement.png'

export default function LargeHomeScreen() {
    return (
        <div className="large">
            <Header />
            <div className='large_home_body'>
                <div classname='textbox'>
                    <p className='heading_text'>Confidentiality Agreements</p>
                    <p className='default_font'>Below are the standard Confidentiality Agreements to be used when a Croda company is disclosing confidential information.</p>
                    <p className='default_font'>There are two versions for each region:</p>
                    <ol>
                        <li>
                            <p className='default_font'>The one-way agreement is to be used when only Croda is disclosing confidential information.
                            </p>
                        </li>
                        <li>
                            <p className='default_font'>The two-way agreement is to be used when both Croda and the other party are (or may be) disclosing confidential information.
                            </p>
                        </li>
                    </ol>
                    <p className='default_font'>Please read the guidance on the use of Confidentiality Agreements prior to using the letter. If you have any queries which are not covered by the guidance, please contact your local regional legal counsel.
                    </p>
                    <a className="hyperlink" href={guidancePDF}>Guidance_Notes_-_Confidentiality_Agreements.pdf
                    </a>
                    <p className='default_font'>Croda's Confidentiality Agreement Playbook
                    </p>
                    <p className='default_font'>We have produced some guidance for you to refer to and use when negotiating confidentiality agreements, please refer to this document (below) to help you make a decision on whether or not to accept a change to our standard agreement.
                    </p>
                    <a className="hyperlink" href={PlaybookPDF}>Confidentiality Agreement Croda Playbook.pdf</a>
                </div>
                <div className='column'>
                    <div className='image_and_buttons'>
                        <img src={confidentialityAgreement} alt="Drawer Icon" className='contract_image' />
                    </div>
                    <NavigationHomeButton name='Create Agreement' location='create_agreement' icon={<HiDocumentText style={iconStyle} />} />
                    <NavigationHomeButton name='View Agreements' icon={<AiFillFolderOpen style={iconStyle} />} />
                </div>
            </div>
        </div>
    )
}

const iconStyle = {
    marginLeft: '1rem',
    fontSize: '2rem',
    color: 'white'
}