import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AgreementText from "../TestFiles/AgreementText";
import Database from "../TestFiles/Database";
import Header from "../UniversalComponents/Header";

export default function DisplayDocument() {
    const [text, setText] = useState('this')

    const params = useParams();
    console.log(params)
    const GUID = params.GUID;

    useEffect(() => {
        getAgreement()
    })

    const getAgreement = () => {
        const Agreement = Database.filter((item) => item.id === GUID)[0]
        const AgreementRegion = Agreement.regionLanguage
        const AgreementType = Agreement.type
        console.log(AgreementRegion + " " + AgreementType)
        const currentAgreement = AgreementText.filter((item) => item.FileRegion === AgreementRegion && item.FileType === AgreementType)[0]
        console.log(currentAgreement)
        let text = currentAgreement.Data
        text = text.replaceAll("***Today'sDate***", new Date().toISOString().split('T')[0])
        if (AgreementRegion === "EU/ASIA") {
            text = text.replaceAll('***ThisCompany***', Agreement.internalCompanyName ? Agreement.internalCompanyName.bold() : 'Internal Company Name'.bold())
            text = text.replaceAll('***ThisCompanyShortName***', Agreement.internalCompanyShortName ? Agreement.internalCompanyShortName.bold() : 'Internal Company Short Name'.bold())
            text = text.replaceAll('***TheProject***', Agreement.projectDescription ? Agreement.projectDescription.bold() : 'Project Description'.bold())
            text = text.replaceAll('***ThatCompany***', Agreement.externalCompanyName ? Agreement.externalCompanyName.bold() : 'External Company Name'.bold())
            text = text.replaceAll('***ThatCompanyKnownAs***', Agreement.externalCompanyShortName ? Agreement.externalCompanyShortName.bold() : 'Short External Company Name'.bold())
            text = text.replaceAll('*** JobTitle ***', Agreement.internalJobTitle ? Agreement.internalJobTitle.bold() : 'Internal Job Title'.bold())
            text = text.replaceAll('*** InitialsAndSurname ***', Agreement.internalRecipientName ? Agreement.internalRecipientName.bold() : 'Internal Recipient Name'.bold())
            text = text.replaceAll('***FAO***', Agreement.externalRecipientName ? Agreement.externalRecipientName.bold() : 'External Recipient Name'.bold())
        }
        setText(text)
    }

    if (Database.length === 0) {
        return (
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                You haven't made an agreement yet.
            </div>
        )
    }
    else {
        return (
            <div>
                <Header />
                <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <div style={{ backgroundColor: '#fff', border: '1px solid #ddd', padding: '20px' }}>
                        <p style={{ fontSize: '16px', lineHeight: '1.5' }} dangerouslySetInnerHTML={{ __html: text }}/>
                    </div>
                </div>
            </div>
        );
    }



}