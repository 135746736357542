import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import { AiOutlineDownload } from 'react-icons/ai';
import { AiFillFileText } from 'react-icons/ai';
import Database from '../TestFiles/Database'
import Header from '../UniversalComponents/Header';

export default function AgreementTable() {

    function NavigatePage(GUID) {
        if (GUID) {
            window.location.href = "view/" + GUID
        }
    }

    return (
        <div>
            <Header />
            <div style={{ width: '90%', border: '1px solid #ddd', borderCollapse: 'collapse', margin: '0 auto', marginBottom: '20px', marginTop:20 }}>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'lightGray', justifyContent: 'space-between' }}>
                                <TableCell><p className='table_heading_text'>Name</p></TableCell>
                                <TableCell><p className='table_heading_text'>Internal Company Name</p></TableCell>
                                <TableCell><p className='table_heading_text'>External Company Name</p></TableCell>
                                <TableCell><p className='table_heading_text'>Signer</p></TableCell>
                                <TableCell><p className='table_heading_text'>Agreement Type</p></TableCell>
                                <TableCell align="right"><p className='table_heading_text'>View Online</p></TableCell>
                                <TableCell align="right"><p className='table_heading_text'>Download</p></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Database.map(({ id, senderName, regionLanguage, type, projectName, projectDescription, internalCompanyName, internalCompanyShortName, internalRecipientName, internalRecipientEmail, externalCompanyName, externalCompanyShortName, externalRecipientName, externalRecipientEmail }) => (
                                <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{projectName}</TableCell>
                                    <TableCell>{internalCompanyName}</TableCell>
                                    <TableCell>{externalCompanyName}</TableCell>
                                    <TableCell >{internalRecipientName}</TableCell>
                                    <TableCell>{type}</TableCell>
                                    <TableCell align='right' style={{ paddingRight: '3rem', cursor: 'pointer' }}><AiFillFileText style={{ height: 50, fontSize: '2.5rem' }} onClick={() => NavigatePage(id)} /></TableCell>
                                    <TableCell align='right' style={{ paddingRight: '2.5rem' }}><AiOutlineDownload style={{ height: 50, fontSize: '2.5rem', cursor: 'pointer' }} onClick={() => NavigatePage(id)} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>

    );
}