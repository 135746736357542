import {CgMenuGridO} from 'react-icons/cg'
import {AiFillFolderOpen, AiOutlineDownload} from 'react-icons/ai'
import Drawer from 'react-modern-drawer'
import React from 'react'
import "react-modern-drawer/dist/index.css";
import NavigationHomeButton from '../HomeScreen/Components/NavigationHomeButtons';
import {AiFillHome} from 'react-icons/ai'
import {HiDocumentText} from 'react-icons/hi'

export default function Header() {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <div>
            <div className="header">
                <CgMenuGridO className='drawer_button' onClick={toggleDrawer} style={{color: "white", width: '2rem'}}/>
                <img src={require('../Images/Croda_Text.png')} alt='logo' style={{marginLeft: 20}}/>
                <h1 className="title">Croda Privacy Agreement</h1>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='side-drawer'
                size={300}
                enableOverlay='enable'>
                <div className='column'>
                    <h1 className='default_font'>Welcome user!</h1>
                    <NavigationHomeButton name='Home Page' location='/' icon={<AiFillHome style={iconStyle}/>}/>
                    <NavigationHomeButton name='Create Agreement' location='/create_agreement' icon={<HiDocumentText style={iconStyle}/>}/>
                    <NavigationHomeButton name='View Agreements' location='/agreement_list' icon={<AiFillFolderOpen style={iconStyle}/>}/>
                    <NavigationHomeButton name='Download Documents' location='/download_documents' icon={<AiOutlineDownload style={iconStyle}/>}/>
                    <NavigationHomeButton name='Temporary API' location='/temp' icon={<AiOutlineDownload style={iconStyle}/>}/>
                </div>
            </Drawer>
        </div>

    )
}

const iconStyle ={
    marginLeft: '1rem',
    fontSize: '2rem',
    color: 'white'
}